import React from 'react'
import Hero from '../components/Hero'
import About from '../components/About'
import UnitType from '../components/UnitType'
import Pricing from '../components/Pricing'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import Compounds from '../components/Compounds/Compounds'
import Features from '../components/Features/Features'
import NewCairo from '../components/NewCairo/NewCairo'
import Butterfly from '../components/Butterfly/Butterfly'
import FeaturesButterfly from '../components/FeaturesButterfly/FeaturesButterfly'

const Home = () => {
  return (
    <div>
      <Hero />
      <Compounds />
      <About />
      <UnitType />
      <Pricing price={'أسعار تبدأ من 10 مليون '}/>
      <NewCairo />
      <Features />
      <Pricing price={'أسعار تبدأ من 6 مليون '}/>
      <Butterfly />
      <FeaturesButterfly />
      <Pricing price={'أسعار تبدأ من 16 مليون '}/>
      <Contact />
      <Footer />
    </div>
  )
}

export default Home