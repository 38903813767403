export const texts = [
    'مدينة متكاملة تجسد مفهوم الرفاهية والاستقرار',
    'من تطوير شركة مدينة نصر للإسكان والتعمير.',
    'موقع استراتيجي بالقرب من مطار القاهرة الدولي',
    'مجموعة متنوعة من الوحدات السكنية.',
    'فلل فاخرة بتصاميم عصرية.',
    'فلل فاخرة بتصاميم عصرية.',
    'وسائل راحة متكاملة.',
    'تم الاستعانة بفريق متكامل من المهندسين المعماريين لتصميم المشروع.',
    'يضم منطقة اعمال بمثابة مركز إداري.',
    'مقدم يبدأ من 5%.'
] 
export const compoundCity = [
    'خدمات أمنية توفر أقصى درجات الأمان بأفراد أمن مدربين على أعلى مستوى.',
    'كاميرات مراقبة تعمل على مدار اليوم لرصد كافة التحركات',
    'العديد من المساحات الخضراء والحدائق التي تعزز من شعور الراحة والاسترخاء.',
    'يتوفر العديد من المطاعم والكافيهات التي تقدم ألذ المأكولات والمشروبات',
    'مناطق مخصصة للاطفال تضم العديد من الالعاب الهامة والمختلف التي تناسب مختلف الأعمار.',
    'خدمات النظافة متوفرة على مدار الساعة.',
    'مولدات كهربائية التى تعمل تلقائيا عن حدوث اي خلل او انقطاع فى الكهرباء.',
    'قاعات مخصصة للحفلات والمناسبات لعقد الاجتماعات الهامة.',
    'أنظمة إطفاء حرائق تعمل تلقائيا عن استشعار الدخان',
    'صيدليات توفر العديد من الأدوية ومستحضرات التجميل',
    'حمامات سباحة تناسب أعمار السكان المختلفة.',
    'تحافظ الجراجات الذكية على سلامة السيارات.',
    'تعمل خدمات الصيانة على تصليح الأعطال والتلفيات.',
]
export const featuresButterfly= [
    'يوفر الكمبوند لسكانه حياة راقية وهادئة',
    'يضم مساحات خضراء واسعة،',
    'ومرافق رياضية وترفيهية متنوعة.',
    'تم تصميم الكمبوند بأحدث المعايير الهندسية لضمان أعلى مستوى من الراحة والأمان'
]