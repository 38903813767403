import { InputLabel } from '@mui/material'
import React from 'react'

const FormInput = ({ label, type }) => {
    return (
        <>
            <InputLabel htmlFor={type} sx={{ my: 1, textAlign:'start' }} >
                {type !== 'message' && <><span style={{ color: '#f00' }} >*</span></>} {label} 
            </InputLabel>
            
            {type === 'message' ? <textarea
                style={{ backgroundColor: '#fff', height: '150px', padding: '20px', resize: 'none' }} 
                id={type}
                multiline={'true'}
                rows={6}
                variant={type}
                size="medium"
                name={type}
                className='input'
            /> : <input
                required
                style={{ backgroundColor: '#fff' }}
                id={type}
                type={type}
                name={type}
                className='input' />}
        </>
    )
}

export default FormInput