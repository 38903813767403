import React from 'react'
import WrapperSection from '../global/WrapperSection'
import { CardMedia, Grid } from '@mui/material'
import about from './../../assets/about/about.png'
import Description from '../global/Description'
const About = () => {
    return (
        <>
            <div id="course">
                <WrapperSection>
                    <Grid container spacing={2} alignItems={'center'}>
                        <Grid item md={6} xs={12}>
                            <Description
                                title={'كمبوند تاج سيتي القاهرة الجديدة  Taj City New Cairo Madinet Masr'}
                                description={` مدينة متكاملة ومتعددة الاستخدامات، من تطوير شركة مدينة مصر للتطوير العقاري، توفر خيارات سكنية تتنوع ما بين استوديوهات، شقق، دوبلكس، وفلل، بمساحات تبدأ من 50 متر مربع، وتصل حتى 1000 متر مربع، وتصاميم عصرية تمزج بين التصاميم الإيطالية والفرنسية واليونانية.
يتميز الكمبوند بموقعه الهام والحيوي في قلب القاهرة الجديدة، وتحديدا على امتداد شارع الثورة، وهذا يعطيه إطلالة على واجهتين وهما طريق السويس والتجمع الأول، كما يتوفر به باقة من أفضل المرافق والخدمات الأساسية والترفيهية والتجارية وغيرها.
يمتد مشروع تاج سيتى التجمع على مساحة ضخمة بلغت 3.7 مليون متر مربع، مقسمة على 9 مراحل، لكل مرحلة ما يميزها سواء في التصميم والتخطيط، أو أنواع الوحدات ومساحتها، وأيضا كل مرحلة مزودة بكافة المرافق والخدمات التي تجعلها بمثابة كمبوند متكامل، يمكنك حجز وحدتك بأقل مقدم حجز، وأطول فترة سداد تصل حتى 8 سنوات.`}/>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <CardMedia
                                component={'img'}
                                src={about}
                                sx={{ width: '100%', objectFit: 'contain' }}
                            />
                        </Grid>
                    </Grid>
                </WrapperSection>
            </div>
        </>
    )
}

export default About