import { Box, CardMedia, Grid, Typography } from '@mui/material'
import React from 'react'
import { compoundCity } from '../../constent'
import Item from '../global/Item'
import { unitCity } from '../../constent/compound'
import img from './../../assets/about/about.png'
import arrow from './../../assets/global/123.png'
const Features = () => {
    return (
        <>
            <Box position={'relative'} sx={{ backgroundImage: `url(${img})`, height: { md: '40rem', xs: '150vh' }, display: 'flex', alignItems: 'center', backgroundSize: '100% 100%', position: 'relative', mb: '5%' }} >
                <div className="overlayNewProject">
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3, mx: 2, p: { md: 4, xs: 1 } }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                            <Typography color={'#fff'}>وسائل الراحة المتوفرة في كمبوند سراي</Typography>
                            <CardMedia
                                component={'img'}
                                src={arrow}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }} >
                            {compoundCity?.map(res => <Item text={res} />)}
                        </Box>
                    </Box>
                </div>
            </Box>
            <Box sx={{ my: 4, mt: '-10%', position: 'relative', zIndex: 3 }} >
                <Grid container alignItems={'center'} justifyContent={"center"}>
                    {unitCity?.map(res =>
                        <Grid item md={2.2} xs={12}>
                            <CardMedia
                                component={'img'}
                                src={res?.image}
                                sx={{ m: '8px auto', width: '90%' }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    )
}

export default Features