import { Box, Typography } from '@mui/material'
import React from 'react'
import Title from './Title';
import Btn from './Buttons/Btn';

const Description = ({ title, description, supDescription, text = 'See More', path }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, alignItems: { md: 'normal', xs: 'center' } }} >
            <Title color='primary.main' title={title} align='flex-start' />
            {description &&
                <Typography color={'#000'} sx={{
                    fontWeight:'500',
                    lineHeight: 1.8, wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                }}>{description}</Typography>
            }
            {supDescription &&
                <Typography color='#000' sx={{
                    fontWeight:'500',
                    position: 'relative',
                    lineHeight: 2,
                    textWrap: 'break',
                    ml: 3, 
                }} >
                    {supDescription}
                </Typography>
            }
            {path && <Btn text={text} path={path} bg={'#F6BB25'} />}
        </Box>
    )
}

export default Description