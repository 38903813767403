import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'

const Card = ({ src, text }) => {
    return (
        <>
            <Box position={'relative'} >
                <CardMedia
                    component={'img'}
                    src={src}
                    sx={{ borderRadius:'30px 0 100px'}}
                />
                <Typography sx={{ 
                    backgroundColor:'rgba(217, 217, 217, 0.75)',
                    py:1,px:2,
                    borderRadius:2,
                    textAlign:'center',
                    position:'absolute',
                    top:'50%',
                    left:'50%',
                    transform:'translate(-50%, -50%)',
                    width:'75%',
                }} color={'primary'} variant='h6' >
                    {text}
                </Typography>
            </Box>
        </>
    )
}

export default Card