import { Box, Button, Typography } from '@mui/material' 
import React from 'react'
import { Helmet } from 'react-helmet-async';

function SubmitDone() {
    const goBack = () => {
        window.history.back();
    };

    return (
        <>
        <Helmet>
                <title>Done</title>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11464092786"></script>
                <script>{`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'AW-11464092786');
                `}</script>
                <script>{`
                    gtag('event', 'conversion', {
                        'send_to': 'AW-11464092786/XE5LCKvj8dsZEPLYwNoq',
                        'value': 1.0,
                        'currency': 'AED'
                    });
                `}</script>
            </Helmet>
            <Box sx={{
                margin: { md: "15px", xs: "5px" },
                height: "85vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                color: "#ffb000",
                textAlign: "center",
                flexDirection: "column",
            }} >
                <Typography sx={{
                    textAlign: "right",
                }} >
                    شكرا لك ..<br />
                    تم إرسال طلبك بنجاح .. <br />
                    سنقوم بالتواصل معك في أقرب وقت ممكن
                </Typography>
                <Box pb={2} textAlign={'center'} >
                    <Button variant="contained"
                        onClick={goBack}
                        sx={{
                            backgroundColor: '#062371', ':hover': {
                                backgroundColor: '#062371'
                            },
                            margin: "25px auto",
                            color: "#fff",
                            fontWeight: 'bold',
                            width: 'fit-content',
                            textAlign: 'center',
                            px: 2,
                            py: 1

                        }}>العودة الى الصفحة الرئيسية</Button>
                </Box>
            </Box>
        </>
    )
}

export default SubmitDone