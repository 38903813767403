import { Box, Typography } from '@mui/material'
import React from 'react'

const Item = ({text}) => {
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, background: 'rgba(217, 217, 217, 1)', borderRadius: '8px 0 18px', p: 1, mb: { md: '16px' ,xs:0} }}>
                <Typography sx={{ width: '16px', height: '16px', border: '2px solid #fff', background:'#D63449', borderRadius:'50%'}}/>
                <Typography color={'primary'} >{text}</Typography>
            </Box>
        </>
    )
}

export default Item