import { Box, Divider, Typography } from '@mui/material'
import React from 'react'

const Title = ({title ='تواصل معنا'}) => {
    return (
        <>
            <Box sx={{width:'fit-content'}}>
                <Typography variant={'h5'} color={'primary.main'} mb={1} >{title}</Typography>
                <Divider sx={{ borderColor: '#f00000', borderWidth: '1px', width: '7rem', mb: 1 }} />
                <Divider sx={{ borderColor: '#f00000', borderWidth: '1px', width: '50%' }} />
            </Box>
        </>
    )
}

export default Title