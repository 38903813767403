import React from 'react'
import { Box, CardMedia, Typography } from '@mui/material'
import img from './../../assets/about/about.png'
import arrow from './../../assets/global/123.png'
import './style.css'
import Item from '../global/Item'
import { featuresButterfly } from '../../constent'

const FeaturesButterfly = () => {
    return (
        <>
            <Box position={'relative'} sx={{ backgroundImage: `url(${img})`, height: { md: '25rem', xs: '100vh' }, display: 'flex', alignItems: 'center', backgroundSize: '100% 100%', position: 'relative', mb: '5%' }} >
                <div className="overlayNewProject">
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3, mx: 2, p: { md: 4, xs: 1 } }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                            <Typography color={'#fff'}>أبرز الملامح الرئيسية ذا باترفلاي</Typography>
                            <CardMedia
                                component={'img'}
                                src={arrow}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }} >
                            {featuresButterfly?.map(res => <Item text={res} />)}
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                            <Typography color={'#fff'}>موقع ذا باترفلاي The butterfly القاهؤة الجديدة</Typography>
                            <CardMedia
                                component={'img'}
                                src={arrow}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                        <Typography color={'#fff'}>اختارت شركة مدينة مصر MNHD موقعًا استراتيجيًا لمشروعها الجديد في مدينة المستقبل أحد أرقى المناطق السكنية الحديثة. يقع المشروع على محور الأمل، بالقرب من مدينتي، مما يجعله محاطًا بكافة الخدمات والمنشآت التي تحتاجها أنت وعائلتك. استمتع بسهولة الوصول إلى أشهر الجامعات والمدارس والمولات التجارية، وعش تجربة سكنية فريدة في بيئة هادئة ومناسبة للعائلات يقع الكمبوند على مسافة قريبة من . محور الأمل طريق السويس والطريق الدائري يبعد عدة دقائق عن الجامعة الأمريكية</Typography>
                    </Box>
                </div>
            </Box>
        </>

    )
}

export default FeaturesButterfly