import { Grid } from '@mui/material'
import React from 'react'
import { compound } from '../../constent/compound'
import Card from './Card'
import WrapperSection from '../global/WrapperSection'

const Compounds = () => {
    return (
        <>
            <WrapperSection>
                <Grid container spacing={2} alignItems={'center'}>
                    {compound?.map(res =>
                        <Grid item md={4} xs={12}>
                            <Card src={res?.image} text={res?.text} />
                        </Grid>
                    )}
                </Grid>
            </WrapperSection>
        </>
    )
}

export default Compounds