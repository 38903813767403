import React from 'react'
import WrapperSection from '../global/WrapperSection'
import { CardMedia, Grid } from '@mui/material'
import Description from '../global/Description'
import about from './../../assets/about/about.png'

const NewCairo = () => {
    return (
        <div id="course">
            <WrapperSection>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item md={6} xs={12}>
                        <Description
                            title={'كمبوند سراي القاهرة الجديدة sarai new cairo،'}
                            description={` هو أحد أفضل مشاريع شركة مدينة مصر للإسكان والتعمير، والذي ينفرد بموقع استراتيجي بجوار مدينتي وعلى طريق السويس مُباشرة، والذي يوفر مجموعة حصرية من الوحدات السكنية المتنوعة بين شقق سكنية، دوبلكس، فلل مستقلة، منازل تاون هاوس وتوين هاوس، بمساحات مختلفة تبدأ من 126 متر مربع.
يجسد المشروع أحلامك للعيش فى بيئة مثالية تُحيطك بالخدمات والمرافق المتكاملة سواء كانت الاساسية او الترفيهية،لتلبي جميع احتياجاتك، وتضفي على حياتك شعورا بالراحة والاسترخاء، فمع المساحات الخضراء الواسعة التي تغطي معظم مساحة المشروع تنعم بجو هادى يضفى على حياتك لمسة من التميز والسعادة التى طالما تحلم بيها.
وسائل الراحة المتوفرة في كمبوند سراي
حرصت شركة مدينة مصر على تقديم كافة الخدمات والمرافق سوءا كانت الاساسية او الترفيهيه، لتوفير كافة سبل الراحة لعملائها، والعيش فى مجتمع متكامل لا ينقصه شى ويملؤه الرفاهية والسعادة ويعزز من شعور الراحة واخلاق أجواء تغمرها السرور
`} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CardMedia
                            component={'img'}
                            src={about}
                            sx={{ width: '100%', objectFit: 'contain' }}
                        />
                    </Grid>
                </Grid>
            </WrapperSection>
        </div>
    )
}

export default NewCairo