import React from 'react'
import { Box, CardMedia, Grid, Typography } from '@mui/material'
import img from './../../assets/about/about.png'
import arrow from './../../assets/global/123.png'
import './style.css'
import Item from '../global/Item' 
import { texts } from '../../constent'
import {  unit } from '../../constent/compound'
const UnitType = () => {

    return (
        <>
            <Box position={'relative'} sx={{ backgroundImage: `url(${img})`, height: { md: '30rem', xs: '100vh' }, display: 'flex', alignItems: 'center', backgroundSize: '100% 100%', position: 'relative' }} >
                <div className="overlayNewProject">
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', gap: 3, mx: 2, p: { md: 4 ,xs:1} }}>
                        <Box sx={{display:'flex', alignItems:'center', gap:1}} >
                            <Typography color={'#fff'}>أبرز الملامح الرئيسية لتاج سيتي</Typography>
                            <CardMedia 
                                component={'img'}
                                src={arrow}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                        <Box sx={{display:'flex', flexWrap:'wrap', gap:1}} >
                            {texts?.map(res => <Item text={res}/>)}
                        </Box>
                        <Box sx={{display:'flex', alignItems:'center', gap:1}} >
                            <Typography color={'#fff'}>انواع الوحدات</Typography>
                            <CardMedia 
                                component={'img'}
                                src={arrow}
                                sx={{ width: '50px' }}
                            />
                        </Box>
                    </Box>
                </div>
            </Box>
            <Box sx={{my:4, mt:'-5%',position:'relative', zIndex:3}} >
                <Grid container alignItems={'center'}>
                    {unit?.map(res =>
                        <Grid item md={3} xs={12}>
                            <CardMedia
                                component={'img'}
                                src={res?.image}
                                sx={{ m:'8px auto', width: '300px' }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
        </>
    )
}

export default UnitType