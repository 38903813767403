import { CssBaseline } from '@mui/material'; 
import './App.css';
import ScrollToTopButton from './components/global/ScrollToTopButton'; 
import { Navigate, Route, Routes } from 'react-router-dom';
import SubmitDone from './pages/SubmitDone';
import Home from './pages/Home';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
    <div className="App">
      <ScrollToTopButton />
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/home" element={<Home />} /> 
          {/* SuccessPage */}
        <Route path="/done" element={<SubmitDone />} />
      </Routes>
    </div>
    </HelmetProvider>
  );
}

export default App;
