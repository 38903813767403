import React from 'react'
import WrapperSection from '../global/WrapperSection'
import { CardMedia, Grid } from '@mui/material'
import Description from '../global/Description'
import about from './../../assets/about/about.png'

const Butterfly = () => {
    return (
        <div id="course">
            <WrapperSection>
                <Grid container spacing={2} alignItems={'center'}>
                    <Grid item md={6} xs={12}>
                        <Description
                            title={'ذا باترفلاي The butterfly القاهرة الجديدة'}
                            description={`امتلك الآن وحدتك داخل كمبوند  The butterfly أحدث مشروعات شركة مدينة مصر للإسكان والتعمير في القاهرة الجديدة، حيث اختارت أفضل المواقع الاستراتيجية بمدينة المُستقبل سيتي، وتحديدًا بالقرب من محور الأمل.`} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <CardMedia
                            component={'img'}
                            src={about}
                            sx={{ width: '100%', objectFit: 'contain' }}
                        />
                    </Grid>
                </Grid>
            </WrapperSection>
        </div>
    )
}

export default Butterfly