import React from 'react';
import { Avatar, Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const ScrollToTopButton = () => {

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    // const handleFullScreen = () => {
    //     if (!document.fullscreenElement) {
    //         document.documentElement.requestFullscreen();
    //     } else {
    //         if (document.exitFullscreen) {
    //             document.exitFullscreen();
    //         }
    //     }
    // };

    return (
        <>
            <Fab
                color="primary"
                onClick={handleScrollToTop}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 'auto',
                    left:  16,
                    zIndex: 1000,
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '8px',
                }}
            >
                <ArrowUpwardIcon />
            </Fab>

            <Fab
                color="primary"
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16 ,
                    left: 'auto',
                    zIndex: 1000,
                    width: '2rem',
                    height: '2rem',
                    borderRadius: '8px',
                }}
            >
                <a
                    href="https://wa.me/201111590329"
                    target='_blank'
                    rel="noreferrer"
                    style={{
                        color: "inherit",
                        textDecoration: "none",
                        fontSize: 15,
                        fontWeight: "bold",
                    }}>
                    <Avatar sx={{ width: "50px", height: "50px", backgroundColor: "green" }} >
                        <WhatsAppIcon />
                    </Avatar>
                </a>
            </Fab>

        </>
    );
};

export default ScrollToTopButton;
