import React from 'react'
import WrapperSection from '../global/WrapperSection'
import { Box } from '@mui/material'
import Form from './Form'
import Title from '../global/Title'

const Contact = () => {
    return (
        <>
            <div id="contact">
                <WrapperSection >
                    <Box sx={{ p: 2, borderRadius: '16px', backgroundColor: '#F2F2F2', width: '100%' }} >
                        <Title />
                        <Form />
                    </Box>
                </WrapperSection>
            </div>
        </>
    )
}

export default Contact