import { Box, CardMedia, Container, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import hero from './../../assets/Hero/hero.png'
import { useTheme } from '@emotion/react';
import logo from './../../assets/Logo/logo.png';

const Hero = () => {

    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <Box position={'relative'} sx={{
            backgroundImage: `url(${hero})`, height: { md: '85dvh', xs: '60dvh' }, backgroundSize: '100% 100%', position: 'relative',
            display: 'flex', justifyContent: 'flex-start', alignItems: 'center', py: 4
        }} >
            <div className="overlay"></div>
            <Container maxWidth={'100%'} >
                <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', zIndex: 3, gap: 2, alignItems: 'center', mx: 'auto' }} >
                        <CardMedia
                            component={'img'}
                            src={logo}
                            sx={{ objectFit: 'contain', width:'150px' }}
                        />
                    <Typography variant={isMobile ? 'h5' : 'h3'} color={'primary.text'} textAlign={'center'}>
                        أمتلك وحدتك في أفضل مشاريع شركة مدينة مصر
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Hero