import { Button } from '@mui/material'
import React from 'react'
import theme from '../../../utils/theme'

const Btn = ({ bg, color, text, path, type = 'button', width = 'fit-content', handleClick }) => {

    return (
        <>
            <Button
                onClick={handleClick && handleClick}
                type={type}
                size="large"
                sx={{
                    background: bg ? bg : theme.palette.primary.main,
                    color: color ? color : theme.palette.primary.text, textTransform: 'capitalize', px: 4, py: 1, ":hover": {
                        background: theme.palette.secondary.main,
                        color: theme.palette.secondary.text,
                    },
                    width: width,
                    fontWeight:500,
                    textAlign: "center",
                    borderRadius:'8px'
                }} >
                {text}
            </Button>
        </>
    )
}

export default Btn