import React from 'react'
import { Box, Typography } from '@mui/material'

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Box component={'footer'} sx={{
                position: 'relative',
                background: '#062371',
                padding: '16px 64px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: { md: 'space-between' ,xs: 'center'},
                flexWrap: 'wrap-reverse',
            }}>
                <Typography color='footer.link' variant={'h6'} fontWeight={400}
                    sx={{ position: 'relative', zIndex: 3, textAlign: 'center', mt: 2 }} >
                    <a href="tel:+201111590329" style={{textDecoration:'none', color:'#fff'}}>201111590329</a>
                </Typography>
                <Typography color='footer.link' variant={'h6'} fontWeight={400}
                    sx={{ position: 'relative', zIndex: 3, textAlign: 'center', mt: 2 }} >
                    كل الحقوق محفوظة لدى . Aqar Plus {currentYear}
                </Typography>
            </Box>
        </>
    )
}

export default Footer