import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/global/ErrorFallback";
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import theme from './utils/theme';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => window.location.replace("/")}>
          <SnackbarProvider anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <App />
          </SnackbarProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);