import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#256495",
            text: "#fff",
        },
        secondary: {
            main: "#E0E0E0",
            text: "#256495",
        },
        nav: {
            main: "#EFF4F8",
            link: '#131313',
            hover: '#256495'
        },
        footer: {
            main: "#1D262D",
            link: '#fff',
            hover: '#A69F9F'
        },
        background: {
            default: "#fff",
            section: "#f7f7f7",
            card: '#fff',
            input: "#f7f7f7",
            form: "#fff",
            drawer: ''
        },
    },
});

export default theme;
