import i1 from './../assets/compound/1.png'
import i2 from './../assets/compound/2.png'
import i3 from './../assets/compound/3.png'
import u1 from './../assets/unitType/Group 118 (1).png'
import u2 from './../assets/unitType/Group 118 (2).png'
import u3 from './../assets/unitType/Group 161.png'
import u4 from './../assets/unitType/Group 167.png'
import f1 from './../assets/unitType/f1.png'
import f2 from './../assets/unitType/f2.png'
import f3 from './../assets/unitType/f3.png'
import f4 from './../assets/unitType/f4.png'
import f5 from './../assets/unitType/f5.png'

export const compound = [
    {
        id:1,
        text:'The Butterfly',
        image: i1
    },
    {
        id:2,
        text: 'كمبوند تاج سيتي القاهرة الجديدة',
        image: i2
    },
    {
        id:3,
        text:'كمبوند سراي القاهرة الجديدة،',
        image: i3
    },
]
export const unit = [
    {
        id:1,
        image: u1
    },
    {
        id:2,
        image: u2
    },
    {
        id:3,
        image: u3
    },
    {
        id:4,
        image: u4
    },
]
export const unitCity = [
    {
        id:1,
        image: f1
    },
    {
        id:2,
        image: f2
    },
    {
        id:3,
        image: f3
    },
    {
        id:4,
        image: f4
    },
    {
        id:5,
        image: f5
    },
]


