import { Box } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './form.css'
import FormInput from './FormInput';
import Btn from './../global/Buttons/Btn';
import { useNavigate } from 'react-router-dom';

const Form = () => {
    const navigate = useNavigate()

    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_om3c7x1', 'template_jtrpdtn', form.current, '58HKGOImAAN33M7Hl')
            .then((result) => {

                console.log(result.text);
                
                navigate('/done')
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <div>
            <Box component={'form'} ref={form} onSubmit={sendEmail} >
                <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} >
                    <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}>
                        <FormInput label={'الأسم'} type={'user_name'} />
                    </Box>
                    <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                        <FormInput label={'رقم التليفون'} type={'phone_number'} />
                    </Box>
                    <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                        <FormInput label={'رقم الواتساب'} type={'whatsapp_number'} />
                    </Box>
                    <Box sx={{ width: { md: '45%', xs: '100%' }, my: 2 }}  >
                        <FormInput label={'البريد الألكتروني'} type={'email'} />
                    </Box>
                    <Box sx={{ width: { md: '100%', xs: '100%' }, }}  >
                        <FormInput label={'الرسالة'} type={'message'} />
                    </Box>
                </Box>
                <Box mt={2} textAlign={'right'} >
                    <Btn text='ارسال' type='submit' bg={'#062371'} />
                </Box>
            </Box>
        </div >
    )
}
export default Form
