import React from 'react'
import { Avatar, Box, CardMedia, Typography, useMediaQuery } from '@mui/material'
import arrow from './../../assets/global/123.png'
import img from './../../assets/pricing/bgc.png'
import Btn from '../global/Buttons/Btn'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTheme } from '@emotion/react'
const Pricing = ({ price }) => {
    const themeM = useTheme();
    const isMobile = useMediaQuery(themeM.breakpoints.down('sm'));
    return (
        <>
            <Box position={'relative'} sx={{ backgroundImage: `url(${img})`, height: { md: '20rem', xs: '15rem' }, display: 'flex', alignItems: 'center', backgroundSize: '100% 100%', position: 'relative' }} >
                <div className="overlay_white"></div>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', gap: 3, mx: 2, p: { md: 4, xs: 1 }, position: 'relative', zIndex: 9, width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} >
                        <Typography variant={isMobile ? 'h6' : 'h5'} color={'#0B4977'}>{price}</Typography>
                        <CardMedia
                            component={'img'}
                            src={arrow}
                            sx={{ width: '50px' }}
                        />
                    </Box>
                    <a href="#contact" >
                    <Btn text={'سجل الآن'} />
                    </a>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }} >
                        <a
                            href="https://wa.me/201111590329"
                            target='_blank'
                            rel="noreferrer"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 15,
                                fontWeight: "bold",
                            }}>
                            <Avatar sx={{ width: "50px", height: "50px", backgroundColor: "green" }} >
                                <WhatsAppIcon />
                            </Avatar>
                        </a>
                        <a
                        href="tel:+201111590329"
                        target='_blank'
                        rel="noreferrer"
                        style={{
                            color: "inherit",
                            textDecoration: "none",
                            fontSize: 15,
                            fontWeight: "bold",
                        }}>
                        <Avatar sx={{ width: "50px", height: "50px", backgroundColor: "#ff2147" }} >
                            <PhoneIcon />
                        </Avatar>
                    </a>
                </Box>
            </Box>
        </Box >
        </>
    )
}

export default Pricing